/* 
// 8. Features
*/

.feather-bg-img {
    background-position: center center;
    background-size: cover;
}

.icon-feature-1 {
    font-size: 50px;
    color: #3e87db;
}

.icon-feature-2 {
    font-size: 50px;
    color: #fd9644;
}

.icon-feature-3 {
    font-size: 50px;
    color: #46978d;
}

.icon-feature-4 {
    font-size: 50px;
    color: #ee5253;
}

.icon-feature-5 {
    font-size: 50px;
    color: #3f51b5;
}

.icon-feature-6 {
    font-size: 50px;
    color: #00bcd4;
}

.icon-feature-7 {
    font-size: 50px;
    color: #f5bc42;
}

.icon-feature-8 {
    font-size: 50px;
    color: #2ac081;
}

.icon-feature-9 {
    font-size: 50px;
    color: #c43eae;
}

.icon-feature-10 {
    font-size: 50px;
    color: #8bc43e;
}

.icon-feature-11 {
    font-size: 50px;
    color: #6557d6;
}

.icon-feature-12 {
    font-size: 50px;
    color: #02bbb2;
}

.feature-text {
    font-size: 15.5px !important;
}